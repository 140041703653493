import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    start: String,
    finish: String,
    isAdmin: Boolean,
    filteredExamsPath: String,
  };

  connect() {
    // Handle user exams
    let user_exams = $('#toggle-user-exams');
    let user_container = $('#user-container');

    user_exams.prop('disabled', true);
    user_exams.selectpicker('refresh');
    user_container.hide();

    // Handle special permissions
    const exam_target = $('#exam-target option:selected').text();
    let selectWrapper = document.getElementById('student-ids');
    let select = document.getElementById('student-ids-select');
    if (exam_target === 'Alunos específicos') {
      selectWrapper.classList.remove('d-none');
      select.disabled = false;
      select.classList.remove('d-none');
    } else {
      selectWrapper.classList.add('d-none');
      select.disabled = true;
      select.classList.add('d-none');
    }
  }

  toggleDateEdit(event) {
    const checkbox = event.target;
    document.getElementById('datetimepicker-start').disabled = !checkbox.checked;
    document.getElementById('datetimepicker-finish').disabled = !checkbox.checked;
    if (checkbox.checked) {
      document.getElementById('dates-wrapper').classList.add('weak-primary-border');
    } else {
      document.getElementById('dates-wrapper').classList.remove('weak-primary-border');
    }
  }

  toggleStudentIds(event) {
    const targetSelect = event.target;
    let selectWrapper = document.getElementById('student-ids');
    let select = document.getElementById('student-ids-select');
    if (targetSelect.value === 'Alunos específicos') {
      selectWrapper.classList.remove('d-none');
      select.disabled = false;
      select.classList.remove('d-none');
    } else {
      selectWrapper.classList.add('d-none');
      select.disabled = true;
      select.classList.add('d-none');
    }
  }

  toggleActivities(event) {
    const checkbox = event.target;

    let perm_container = $('#permission-container');
    let user_container = $('#user-container');

    let permission_exams = $('#toggle-permission-exams');
    let user_exams = $('#toggle-user-exams');

    if (checkbox.checked) {
      // User exams
      user_container.show();
      user_exams.prop('disabled', false);
      user_exams.selectpicker('refresh');

      perm_container.hide();
      permission_exams.prop('disabled', true);
      permission_exams.selectpicker('refresh');
    } else {
      // Permission exams
      perm_container.show();
      permission_exams.prop('disabled', false);
      permission_exams.selectpicker('refresh');

      user_container.hide();
      user_exams.prop('disabled', true);
      user_exams.selectpicker('refresh');
    }

    permission_exams.selectpicker('refresh');
    user_exams.selectpicker('refresh');
  }

  handleYearSelect(event) {
    const select = event.target;

    let params = new URLSearchParams(window.location.search);

    // Adiciona novos parâmetros ou substitui os existentes
    params.set('exam_year', select.value);

    const url = `${window.location.pathname}?${params}`;

    history.pushState({}, '', url);

    // Inline for now (better use a target)
    $('#scheduled_exams_model_exams_model_id').select2('change.select2'); // Updates select2
  }
}
