import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['datetimeField'];

  connect() {
    this.datetimeFieldTargets.forEach((field) => {
      const input = field.querySelector('input');
      if (input) {
        input.disabled = true;
      }
    });
  }

  toggle(event) {
    const isEnabled = event.target.checked;
    this.datetimeFieldTargets.forEach((field) => {
      const input = field.querySelector('input');
      if (input) {
        input.disabled = !isEnabled; // Note the ! operator to invert the logic
      }
    });
  }
}
